import React from "react";
import Footer from "../components/Footer";
import FullImage from "../components/FullImage";

import Header from "../components/Header";
import SubHero from "../components/SubHero";
import "./Services.css";

const Services = () => {
  return (
    <div className="services">
      <Header
        headerImg="https://i.ibb.co/vXmfpm7/IMG-3035-1.jpg"
        headerTitle="What We Do"
      />
      <SubHero
        subheroTitle="Creating impact together."
        subheroText="At The FolaDavid Foundation, collaboration is what we’re all about. We work with partners who are committed to making a change in the world.
Our partners range in scope and size from governments and international organizations to local charities and community leaders. Our projects are as diverse as our partners and are tailored meet the challenges of a changing world.
"
      />
      <FullImage fullImg="https://images.unsplash.com/photo-1488265333142-353a12931ee9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" />
      <div className="about-section-2">
        <div className="about-section-2-container">
          <div data-aos="fade-up" className="about-section-2-text">
            <h2>Passion To Make A Difference</h2>
            <p>
              We want Nigeria’s children, mothers, and families to be able to
              live healthy and productive lives, and we want the country’s most
              marginalized people to have opportunities to lift themselves out
              of poverty. We have partnered with various like-minded
              organizations to make this a reality. We also aim to significantly
              reduce preventable deaths in Nigeria by focusing on maternal and
              child health.
              <br />
              <br />
              We ensure that children and mothers are able to have access to
              basic human amenities such as food, drugs, clothing and shelter,
              with the aim of improving their quality of life. To also help
              improve creativity amongst children, we create well-structured
              programs where children can actively participate in events that
              are sure to find and nurture innate talents. The goal of these
              programs is to help give these kids better futures.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
