import React from "react";

import "./FullImage.css";

const FullImage = ({ fullImg }) => {
  return (
    <div className="full-image">
      <img src={fullImg} alt="foladavid care foundation"></img>
    </div>
  );
};

export default FullImage;
