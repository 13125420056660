import React from "react";

const Page404 = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h2>Site Is Under Construction!</h2>
    </div>
  );
};

export default Page404;
