import React from "react";
import { NavLink } from "react-router-dom";

import logo from "../images/logo-white.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-container-grid">
          <div className="footer-left">
            <img className="logo" src={logo} alt="foladavid foundation"></img>
            <div className="layer"></div>
          </div>
          <div className="footer-middle">
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">Who We Are</NavLink>
              </li>
              <li>
                <NavLink to="/services">What We Do</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-right">
            <ul>
              {/* <li>
                <NavLink to="/partners">Our Partners</NavLink>
              </li> */}
              <li>
                <NavLink to="/projects">Our Projects</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="bottom-rule"></div>
        <p>&copy; 2021 Foladavid Care Foundation. All right reserved</p>
        <p>
          Foladavid Care Foundation is a private operating foundation registered
          with the Corporate Affairs Commission in Nigeria. RN162393
        </p>
      </div>
    </div>
  );
};

export default Footer;
