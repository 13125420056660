import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import emailIcon from "../images/email.png";
import instagramIcon from "../images/instagram.png";

import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <Header
        headerImg="https://images.unsplash.com/flagged/photo-1555251255-e9a095d6eb9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        headerTitle="Contact Us"
      />
      <div className="contact-section1">
        <div className="contact-section1-container">
          <h2>
            Reach out to us by sending us an email or connecting with us on our
            social media page.
          </h2>
          <div className="contact-info">
            <a
              href="mailto:foladavidfoundation@yahoo.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={emailIcon} alt="" />
              <h5>Email</h5>
            </a>
            <a
              href="https://www.instagram.com/foladavidfoundation"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="" />
              <h5>Instagram</h5>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
