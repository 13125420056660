import React from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageTextGrid from "../components/ImageTextGrid";
import SubHero from "../components/SubHero";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <Header
        headerImg="https://i.ibb.co/bRKWyLy/IMG-3894-1.jpg"
        headerTitle="Working Together To Ensure Healthy Living And Foster Creativity"
      />
      <SubHero
        subheroTitle="A unique blend of healthcare and creativity as a means to changing
              the world for the better"
        subheroText="At the Foladavid Care Foundation, we have a large collection of
              over 100 healthcare professionals in diverse fields and a working
              network of creatives whom we work with to address challenges
              facing families, communities, and nations. Our mission is to
              provide the necessary connection every community needs to excel
              through promoting healthy living and creativity."
      />
      <ImageTextGrid
        sectionImgUrl="https://i.ibb.co/VMXWW9M/1-BF9-D5-DC-E1-B9-4049-ABC9-D0716158138-B-1.jpg"
        sectionBtnUrl="/about"
        sectionBtnTitle="Learn More About Us"
        sectionHeader="Teamwork And Collaboration Are Fundamental To What We Do"
        sectionText="We always welcome new partners who believe in our goals and currently work with several
              organizations and individuals to achieve them. With a focus on art
              and health, everything we do is guided by our commitment to
              building better lives one by one."
      />
      <ImageTextGrid
        withBtn={false}
        imgFirst={true}
        sectionImgUrl="https://i.ibb.co/hfmk8M5/IMG-8028-1.jpg"
        sectionBtnUrl="/about"
        sectionBtnTitle="Learn About Our Partners"
        sectionHeader="Our Allies Keep Us Moving Forward"
        sectionText="With operations in Nigeria, South Africa, USA and the UK, our partners range from other charities to international organizations and individuals. Although our partners are diverse, they all possess a passion for healthcare and art."
      />
      <div className="home-projects-section">
        <div className="home-projects-section-container">
          <div data-aos="fade-up" className="home-projects-text">
            <h2>Featured Project</h2>
            <p>
              At Foladavid Care Foundation, the work we do is not only
              restricted to a region or cause. We collaborate with
              organizations, governments and individuals to promote human
              welfare, sustainability and cooperation across a wide range of
              projects in many communities.
            </p>
          </div>
          <div data-aos="fade-up" className="home-project">
            <iframe
              className="home-video"
              src="https://www.youtube.com/embed/3QdiTQANhB8"
              title="FolaDavid Care Foundation"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <h5>Covid Medical Outreach To Ibeju Lekki Community</h5>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
