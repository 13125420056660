import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import "./About.css";
import FullImage from "../components/FullImage";

const About = () => {
  return (
    <div className="home">
      <Header
        headerImg="https://i.ibb.co/7pTQvpw/Snapseed-2-1.jpg"
        headerTitle="Who We Are"
      />
      <div className="about-section-1">
        <div className="about-section-1-container">
          <h4 data-aos="fade-up">
            Improving Quality Of Life One Community At A Time.
          </h4>
          <h2 data-aos="fade-up">
            Our mission cuts across several countries and regions to overcome
            challenges facing families, communities, and regions by providing
            them with what they need for healthy living and empower them through
            creative projects, skill acquisition and art entrepreneurship
            development.
          </h2>
        </div>
      </div>
      <FullImage fullImg="https://i.ibb.co/88n5B1b/IMG-2884-1.jpg" />
      <div className="about-section-2">
        <div className="about-section-2-container">
          <div data-aos="fade-up" className="about-section-2-text">
            <h2>What Drives Us</h2>
            <p>
              This foundation was born out of passion for healthcare and
              creativity. Our major aim is to ensure healthy lives and promote
              wellbeing for all ages, and foster a culture of creativity in
              children as a means to empower our communities. We strongly
              believe that good health is the bedrock of humanity and no human
              can grow his or her community and the world at large if not in
              good health because good health supports positive social and
              economic outcomes – both for the individual and society as a
              whole. We also aim to build independent individuals with interests
              in creativity and thus improve mental health A creative act such
              as crafting can help focus the mind, and has even been compared to
              meditation due to its calming effects on the brain and body. Even
              just gardening or sewing releases dopamine, a natural
              anti-depressant. Creativity reduces anxiety, depression, and
              stress.
            </p>
            <br />
            <p>We hope to do our part in promoting healthy wellbeing by;</p>
            <ul>
              <li>Organizing medical outreaches to communities and villages</li>
              <li>
                Learning and spreading what works to make people’s lives
                healthier and improve the health care system.
              </li>
              <li>
                Teaching skills and helping community members hone their
                creative ability.
              </li>
              <li>
                Organizing medical outreaches to communities and villages.
              </li>
              <li>
                Taking a major focus on the SDGs 3 targets and raising
                individuals to support the goal.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
