import React from "react";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./CustomButton.css";

const Button = ({ title, url }) => {
  return (
    <Link to={url}>
      <div className="button">
        <button>{title}</button>
        <div className="span"></div>
        <KeyboardArrowRightIcon />
      </div>
    </Link>
  );
};

export default Button;
