import React from "react";
import Footer from "../components/Footer";
import FullImage from "../components/FullImage";
import Header from "../components/Header";
import SubHero from "../components/SubHero";

import "./Projects.css";

const Projects = () => {
  return (
    <div className="projects">
      <Header
        headerImg="https://i.ibb.co/7bhK7yH/IMG-2725-1.jpg"
        headerTitle="Our Projects"
      />
      <SubHero
        subheroTitle="Creating meaningful impact one community at a time."
        subheroText="At the Foladavid Care Foundation, the work we do cuts across a wide range of causes in healthcare and art. We collaborate with individuals, governments and organizations to pursue healthy living, sustainability through the arts, and cooperation across a wide range of projects in many communities, nations and regions."
      />
      <FullImage fullImg="https://i.ibb.co/8BHQrRn/IMG-20221030-WA0002.jpg" />
      <div className="projects-section-1">
        <div className="projects-section-1-container">
          <div data-aos="fade-up" className="projects-section-1-text">
            <h2>Fola David Foundation Takes Charity To Ikate Community</h2>
            <p>
              Humanitarian, Dr. Fola David, CEO of the Foladavid Care Foundation
              (FCF) has partnered alongside The Dermatology Clinic Lagos to
              bring free medical outreach to the people of Ikate Community. An
              initiative supported by the CMACGM group, the KB KLUB,
              Pharmasolutions, Comedian Alibaba and His Royal Majesty, Oba
              Alayeluwa Saheed Ademola Elegushi, Kusenla III, Oba Elegushi of
              Ikate Kingdom; the outreach aim at providing free medical
              assistance, consultations and free medical check-up to
              participants. Dr. Fola David, founder of the Foladavid Care
              Foundation, renowned Visual Artist and Medical doctor with a
              special interest in dermatology is team lead and has confirmed all
              hands are on deck to make sure all present that day are well
              attended to with readiness for at least five hundred residents at
              the Ikate Primary Health Centre on November 5, 2022. According to
              Dr. Ifeoma Abajue, founder of the Dermatology clinic Lagos, “it is
              proven that prevention is the first line of management of any
              health condition but it is given lowest priority. This is one of
              the reasons why we are doing this.”
              <p className="mt-10">
                The outreach will feature free medical screening including blood
                pressure checks, blood sugar level checks, BMI checks, free
                medical consultation, distribution of mosquito nets and malaria
                testkits, educational sessions, distribution of skin care
                products. A team of medical consultants, doctors and other
                personnel will be available to make this a success. Speaking on
                the outreach, Dr. Fola David noted that even when medical
                resources are available, people often lack access to them for a
                multitude of reasons. We bring skin care, medical care,
                professionals and services to communities. Instead of people
                having to figure out how to access care, we bring the care to
                them in line with our goal to always attempt to help people
                access the care they need. We also believe knowledge is power
                and thus organise ed- ucational talks in communities on along
                with our medical outreaches thereby empowering them through care
                and in- formation which allows them to change all of these
                aspects of their lives and more. HRM Oba Saheed Elegushi has
                pledged supports towards the Successful outcome of the outreach.
              </p>
            </p>
          </div>
        </div>
      </div>
      <FullImage fullImg="https://i.ibb.co/3hCG0cc/CA381-ED9-9-C6-A-4590-AE7-A-CCCE4242-CCDB-1.png" />
      <div className="projects-section-1">
        <div className="projects-section-1-container">
          <div data-aos="fade-up" className="projects-section-1-text">
            <h2>Fola David Secures $25k Grant From South African NLC</h2>
            <p>
              A $25,000 in grant funding through the South Africa National
              Lottery Commission Arts, Culture and Heritage Sector was awarded
              to Nigerian based high profile Visual Artist, Fola David in
              partnership with NORTHCA Development Projects, Lekgoledi Arts and
              Cultural Centre in South Africa to implement a portfolio of visual
              art and digital art developmental projects in Limpopo, Lagos and
              Northern Cape. The grant is aimed at providing young visual
              artists the much needed opportunity to participate and benefit
              from a digital art gallery, visual art talent development and an
              SA-Nigeria Residency and Exchange Initiative. This partnership
              will also see the development of an animated cultural heritage
              project and animation hubs, in conjunction with Digital Canvas
              Academy, across multiple African countries the first of which has
              been established in Limpopo, South Africa. <br />
              <br />
              The head of the Visual Arts development program, Dr. Gbadamosi
              Adefemi Foladavid, explained that the new funding will be used “to
              further the plans for creation of a system where new and budding
              African artists can receive mentorship, training and proper
              representation” The LACC is a women and youth led innovative rural
              based community arts organisation in Limpopo Province. LACC
              continues to pioneer the development, growth and
              professionalisation of the rural creative and cultural arts sector
              through its increased involvement in a number of arts, culture and
              heritage projects. Preparations for the launch of the digital
              gallery are underway and master classes for young artists in the
              listed provinces have begun. The animation hub in Limpopo is now
              full operational and Lagos hub is in progress. Mr. Nkgodisheng
              Livingstone Mphahlele, Business development consultant for LACC,
              says “This International collaboration on this project opens us to
              a lot of possibilities and we are breaking new frontiers in arts,
              culture and heritage in Africa one region at a time” The
              SA-Nigeria Resisdency and Exchange initiative will begin year 2022
              and will see artists from Nigeria be offered fully funded
              residency in South Africa and vice versa on a yearly basis.
            </p>
          </div>
        </div>
      </div>
      <FullImage fullImg="https://i.ibb.co/hMgjL9g/IMG-2636-1.jpg" />
      <div className="projects-section-2">
        <div className="projects-section-2-container">
          <div data-aos="fade-up" className="projects-section-2-text">
            <h2>Three Day Free Medical Outreach In Anambra</h2>
            <p>
              Foladavid Care Foundation partners with Paul Oraijaka Foundation
              to present a 3-day free medical outreach across the three
              senatorial districts in Anambra State, Nigeria with health talks
              in the following regions;
              <ul>
                <li>
                  <strong>Anambra South:</strong> Ihiala LG (Azia Town) - 7
                  <sup>th</sup> January, 2021
                </li>
                <li>
                  <strong>Anambra Central:</strong> Anaocha LG (Agulu Town) - 8
                  <sup>th</sup> January, 2021
                </li>
                <li>
                  <strong>Anambra North:</strong> Ogbaru LG (Atani Town) - 9
                  <sup>th</sup> January, 2021
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <FullImage fullImg="https://i.ibb.co/0tfNrVG/IMG-2602-1.jpg" />
      <Footer />
    </div>
  );
};

export default Projects;
