import React from "react";
import Button from "./CustomButton";

import "./ImageTextGrid.css";

const ImageTextGrid = ({
  sectionImgUrl,
  imgFirst = false,
  sectionHeader,
  sectionText,
  sectionBtnTitle,
  sectionBtnUrl,
  withBtn = true,
}) => {
  return (
    <div className="image-text-container">
      <div className={`image-text-grid ${imgFirst && "reversed-grid"}`}>
        <div className="image-text-txt">
          <h2 data-aos="fade-up">{sectionHeader}</h2>
          <p data-aos="fade-up">{sectionText}</p>
          <div data-aos="fade-up" className="btn-container">
            {withBtn && <Button title={sectionBtnTitle} url={sectionBtnUrl} />}
          </div>
        </div>
        <div data-aos="fade-up" className="image-text-img">
          <img src={sectionImgUrl} alt="FolaDavid Care Foundation" />
        </div>
      </div>
    </div>
  );
};

export default ImageTextGrid;
