import React from "react";

import "./Header.css";
import NavBar from "./Navbar";

const Header = ({ headerImg, headerTitle }) => {
  return (
    <div className="header">
      <img className="header-bgImg" src={headerImg} alt="fola" />
      <div className="layer"></div>
      <NavBar />
      <div className="header-container">
        <div data-aos="fade-up" className="header-text">
          <h1>{headerTitle}</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
