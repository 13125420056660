import React from "react";

import "./SubHero.css";

const SubHero = ({ subheroTitle, subheroText }) => {
  return (
    <div className="subhero">
      <div className="subhero-container">
        <div data-aos="fade-up" className="subhero-left">
          <h2>{subheroTitle}</h2>
        </div>
        <div data-aos="fade-up" className="subhero-right">
          <p>{subheroText}</p>
        </div>
      </div>
    </div>
  );
};

export default SubHero;
