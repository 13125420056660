import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import "./Navbar.css";
import logo from "../images/logo-white.png";

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const handleClick = () => {
    setClicked(!clicked);
  };
  const handleClose = () => {
    setClicked(false);
  };
  const handleScroll = () => {
    if (window.scrollY > 600) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`navbar ${scrolled ? "navbar__sticky" : ""}`}>
      <div className="navbar__container">
        <div className="navbar__logo">
          <NavLink to="/" exact>
            <img className="logo" src={logo} alt="foladavid-foundation" />
          </NavLink>
        </div>
        <ul className="navbar__laptop">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">Who We Are</NavLink>
          </li>
          <li>
            <NavLink to="/services">What We Do</NavLink>
          </li>
          {/* <li>
            <NavLink to="/partners">Our Partners</NavLink>
          </li> */}
          <li>
            <NavLink to="/projects">Our Projects</NavLink>
          </li>
        </ul>
        <div className="navbar__toggler" onClick={handleClick}>
          {clicked ? (
            <i className="cancel fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </div>
      </div>
      <div
        className={`navbar__mobile ${clicked ? "visible" : ""}`}
        onClick={handleClose}
      >
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">Who We Are</NavLink>
          </li>
          <li>
            <NavLink to="/services">What We Do</NavLink>
          </li>
          {/* <li>
            <NavLink to="/partners">Our Partners</NavLink>
          </li> */}
          <li>
            <NavLink to="/projects">Our Projects</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
